import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import PageModuleArray from '@components/system/PageModuleArray'

export default function PageGeneric({
  data,
  location,
}: SOG.Contentful.IPageGeneric): ReactElement {
  const modules = data.contentfulPageGeneric.modules

  return (
    <>
      {!!modules && (
        <PageModuleArray modulearray={modules} location={location} />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query PageGenericByPath($id: String!, $locale: String!) {
    contentfulPageGeneric(id: { eq: $id }, node_locale: { eq: $locale }) {
      modules {
        ...ContentfulModulesFragment
      }
    }
  }
`
